import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { format } from "date-fns";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { applicationEventTimelineConfig } from "./HowToApplyBody.config";

export const HowToApplyBody = () => {
  const {
    allContentfulHowToApplyOpening: {
      edges: {
        0: { node: opening },
      },
    },
  } = useStaticQuery(graphql`
    query HowToApplyOpeningQuery {
      allContentfulHowToApplyOpening(limit: 1) {
        edges {
          node {
            id
            heading
            text {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <div className="layout">
      <h4>{opening.heading}</h4>
      <div className="how-to-apply-content-wrapper">
        <div style={{ maxWidth: "40rem" }}>
          {documentToReactComponents(JSON.parse(opening.text.raw))}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <table>
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Event</th>
              </tr>
            </thead>
            <tbody>
              {applicationEventTimelineConfig.map(({ date, title }) => {
                const formattedDate = format(date, "MM/dd/yyyy");
                return (
                  <tr>
                    <td>{formattedDate}</td>
                    <td>{title}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
