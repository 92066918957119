import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import React from "react";
import { HowToApplyBody } from "./components/how-to-apply-body/HowToApplyBody.component";
import { HowToApplyCta } from "./components/how-to-apply-cta/HowToApplyCta.component";
import { HowToApplyHeading } from "./components/how-to-apply-heading/HowToApplyHeading.component";

export const HowToApply = () => {
  return (
    <main>
      <WhiteGrouping>
        <section className="fix-header-overlap how-to-apply">
          <HowToApplyHeading />
          <HowToApplyBody />
          <HowToApplyCta />
        </section>
      </WhiteGrouping>
      <BlueGrouping>
        <section
          className="layout"
          style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
        />
      </BlueGrouping>
    </main>
  );
};
