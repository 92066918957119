export const applicationEventTimelineConfig = [
  {
    date: 1701763200000,
    title: "Early Admission Application Deadline",
  },
  {
    date: 1704441600000,
    title: "Late Admission Application Deadline",
  },
  {
    date: 1709280000000,
    title: "Deadline for Tuition Assistance Application",
  },
  {
    date: 1709280000000,
    title: "Final Admission Decisions Communicated",
  },
  {
    date: 1711090800000,
    title: "Enrollment Agreement and Tuition Deposit Due",
  },
];
