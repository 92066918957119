import React from "react";
import { Button } from "components/button/Button.component";
import "./StackedCallToAction.styles.scss";
import { Link } from "components/link/Link.component";

export const StackedCallToAction = ({
  headerText,
  paragraphText,
  buttonObj,
}) => {
  return (
    <div className="stacked-call-to-action layer-1">
      <h4 style={{ whiteSpace: "nowrap", margin: 0 }}>{headerText}</h4>
      <p className="schedule-text">{paragraphText}</p>
      <Link
        to={buttonObj.link}
        newTab={buttonObj.newTab}
        className="no-underline"
      >
        <Button
          tabIndex={-1}
          icon={buttonObj.icon}
          color="secondary"
          labelStyle={{ marginLeft: "0.5rem" }}
          hoverAnimation
        >
          {buttonObj.label}
        </Button>
      </Link>
    </div>
  );
};
