import { RELATED_PAGES_LABELS } from "components/constants";
import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React from "react";
import linksService from "services/links.service";
import { HowToApply as HowToApplyModule } from "../modules/how-to-apply/HowToApply.component";

const HowToApply = () => {
  useRelatedPages([
    { label: RELATED_PAGES_LABELS.OUR_STORY, link: linksService.ourStory() },
    {
      label: RELATED_PAGES_LABELS.AFFORDING_PACBAY,
      link: linksService.affordingPacbay(),
    },
  ]);

  return (
    <>
      <SEO title="How To Apply" />
      <HowToApplyModule />
    </>
  );
};

export default HowToApply;
