import {
  APPLY_TO_PACBAY,
  QUESTION_MINIMIZE_BREAKPOINT,
} from "components/constants";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React from "react";
import linksService from "services/links.service";

export const HowToApplyCta = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  return (
    <StackedCallToAction
      headerText={
        isMobile
          ? APPLY_TO_PACBAY.HEADER.MOBILE
          : APPLY_TO_PACBAY.HEADER.DESKTOP
      }
      paragraphText={
        isMobile
          ? APPLY_TO_PACBAY.PARAGRAPH.MOBILE
          : APPLY_TO_PACBAY.PARAGRAPH.DESKTOP
      }
      buttonObj={{
        label: APPLY_TO_PACBAY.BUTTON_LABEL.DESKTOP,
        icon: SVGIcons.Document,
        link: linksService.inquiryForm(),
        newTab: true,
      }}
    />
  );
};
