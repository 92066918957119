import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const HowToApplyHeading = () => {
  return (
    <>
      <ImageHeading heading="How To Apply">
        <StaticImage
          src="../../../../images/jpg/how_to_apply_heading.jpg"
          alt="two students studying together"
          placeholder="blurred"
          layout="constrained"
          style={{
            marginTop: "-5rem",
          }}
        />
      </ImageHeading>
    </>
  );
};
